import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04974892 = () => interopDefault(import('../pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))
const _62e15528 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _4cf94073 = () => interopDefault(import('../pages/downloads.vue' /* webpackChunkName: "pages/downloads" */))
const _94a70c9c = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _6bfdc2bc = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _08048b22 = () => interopDefault(import('../pages/agenda/_slug.vue' /* webpackChunkName: "pages/agenda/_slug" */))
const _98144f2c = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _4f808cda = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _61d0b032 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agenda",
    component: _04974892,
    name: "agenda"
  }, {
    path: "/contact",
    component: _62e15528,
    name: "contact"
  }, {
    path: "/downloads",
    component: _4cf94073,
    name: "downloads"
  }, {
    path: "/nieuws",
    component: _94a70c9c,
    name: "nieuws"
  }, {
    path: "/zoekresultaten",
    component: _6bfdc2bc,
    name: "zoekresultaten"
  }, {
    path: "/agenda/:slug",
    component: _08048b22,
    name: "agenda-slug"
  }, {
    path: "/nieuws/:slug",
    component: _98144f2c,
    name: "nieuws-slug"
  }, {
    path: "/",
    component: _4f808cda,
    name: "index"
  }, {
    path: "/*",
    component: _61d0b032,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
